<template>
  <el-card class='cash-not-account business-handling my-card' :body-style="cardBody">
    <el-page-header @back="$router.go(-1)" :title="$t('Back')">
      <template slot="content"><span style="font-size:14px">{{$t('remittanceBusiness')}} / {{$t('cashNotAccount')}}</span></template>
    </el-page-header>
    <div class="form-container">
      <el-form ref="remitterForm" :model="remitterForm" :rules="rules" label-position="top">
        <el-divider content-position="left">{{$t('remitterInfo')}}</el-divider>
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('firstName')" prop="remittorName">
              <el-input size="medium" v-model="remitterForm.remittorName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('lastName')" prop="remittorSurname">
              <el-input size="medium" v-model="remitterForm.remittorSurname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('phone')" prop="remittorPhone">
              <el-input size="medium" v-model="remitterForm.remittorPhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('idNumber')" prop="remittorIdCard">
              <el-input size="medium" v-model="remitterForm.remittorIdCard"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('beneficiaryInfo')}}</el-divider>
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('firstName')" prop="payeeName">
              <el-input size="medium" v-model="remitterForm.payeeName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('lastName')" prop="payeeSurname">
              <el-input size="medium" v-model="remitterForm.payeeSurname"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('phone')" prop="payeePhone">
              <el-input size="medium" v-model="remitterForm.payeePhone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('idNumber')">
              <el-input size="medium" v-model="remitterForm.payeeIdCard"></el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('beneficiaryAddress')" prop="country_region_city">
              <el-cascader size="medium" v-model="remitterForm.country_region_city" :options="countryTree" :props="cascader"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-divider content-position="left">{{$t('remittanceInfo')}}</el-divider>
        <el-row :gutter="20">
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('remittanceAmount')" prop="amount">
              <el-input size="medium" v-model="remitterForm.amount" @blur="calculateServiceCharge" :disabled="isCompute">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('serviceCharge')+' '+(isCompute?$t('calculating')+'……':'')" prop="serviceChargeAmount">
              <el-input type="number" size="medium" v-model="remitterForm.serviceChargeAmount" disabled :placeholder="$t('AfterEnteringTheAmountItWillBAutomaticallyCalculated')">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>

          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('Service fee discount')" prop="coupon">
              <el-input size="medium" v-model="remitterForm.coupon" type="number">
                <template slot="prepend"><i class="iconfont iconruishifalang" style="font-size:14px"></i> </template>
              </el-input>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('serviceCharge')+$t('payment')" prop="payServiceChargeType">
              <el-select size="medium" v-model="remitterForm.payServiceChargeType">
                <el-option :label="$t('remitterPayment')" :value="0"></el-option>
                <el-option :label="$t('payeePayment')" :value="1"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <transaction-tax-display businessCode="20" :transaction-amount="remitterForm.amount"></transaction-tax-display>
          </el-col>
          <el-col :md="8" :lg="6" :xl="4">
            <el-form-item :label="$t('remittance reason')" prop="purpose">
              <el-select size="medium" v-model="remitterForm.purpose">
                <el-option :label="$t('School aid')" :value="$t('School aid')"></el-option>
                <el-option :label="$t('Family assistance')" :value="$t('Family assistance')"></el-option>
                <el-option :label="$t('Building construction')" :value="$t('Building construction')"></el-option>
                <el-option :label="$t('Business settlement')" :value="$t('Business settlement')"></el-option>
                <el-option :label="$t('Service settlement')" :value="$t('Service settlement')"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-col :span="12">
              <el-form-item :label="$t('remark')">
                <el-input size="medium" type="textarea" v-model="remitterForm.remark"></el-input>
              </el-form-item>
            </el-col>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="bottom-button">
      <el-button v-if="!printParam" :loading="isLoading" size="medium" type="primary" @click="submit('remitterForm')">{{$t('submit')}}</el-button>
      <el-button v-else size="medium" @click="printVisible=true">{{$t('printrRceipt')}}</el-button>
    </div>

    <el-dialog :title="$t('printrRceipt')" :visible.sync="printVisible" width="500px" :modal-append-to-body="false" :destroy-on-close="true" :close-on-press-escape="false" :close-on-click-modal="false">
      <print-credentials :param="printParam" :isClosePrint.sync="printVisible" />
    </el-dialog>
  </el-card>
</template>

<script>
import printCredentials from '@/components/printCredentials'
import transactionInfo from "@/api/transactionInfo";
import TransactionTaxDisplay from '@/components/TransactionTaxDisplay'
import business from '@/api/business'
export default {
  components: { printCredentials, TransactionTaxDisplay },
  data () {
    return {
      isLoading: false,
      remitterForm: { coupon: 0 },
      payeeForm: {},
      isCompute: false,

      printVisible: false,
      printParam: null,
    }
  },

  computed: {
    cascader () { return this.$store.state.format.cascader },
    cardBody () { return this.$store.state.style.cardBody },
    rules () {
      return {
        remittorName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        remittorSurname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        remittorPhone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        remittorIdCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeName: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeSurname: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeePhone: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payeeIdCard: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        amount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        serviceChargeAmount: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        payServiceChargeType: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        purpose: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
        country_region_city: [{ required: true, message: this.$t('requiredFields'), trigger: 'blur' }],
      }
    },
    countryTree () { return this.$store.state.globalParameter.countryTree; }
  },
  watch: {
    /**手续费优惠输入金额后计算优惠后的手续费 */
    "remitterForm.coupon" (newValue) {
      /**优惠手续费金额不能是负数 */
      if (newValue < 0) {
        this.remitterForm.coupon = 0;
        return;
      }
      /**优惠后手续费不能是负数 */
      if (this.remitterForm.purposeBefore - this.remitterForm.coupon < 0) {
        this.remitterForm.coupon = 0;
        return;
      }

      this.$set(this.remitterForm, 'serviceChargeAmount', this.remitterForm.purposeBefore - this.remitterForm.coupon);
    },
    "remitterForm.amount" (newValue) { if (newValue < 0) this.remitterForm.amount = 0 },
  },
  methods: {
    calculateServiceCharge () {
      let _this = this;
      if (!_this.remitterForm.amount) {
        _this.$set(_this.remitterForm, "serviceChargeAmount", undefined)
        return;
      }
      _this.isCompute = true;
      business.serviceChargeCompute({
        param: { amount: _this.remitterForm.amount, businessCode: _this.$route.params.code },
        success: res => {
          setTimeout(() => {
            if (res.code != 200) _this.$message.warning(_this.$t(res.msg));
            /**手续费 */
            _this.$set(_this.remitterForm, "serviceChargeAmount", res.data || 0);
            /**手续费优惠金额 */
            _this.$set(_this.remitterForm, "purposeBefore", res.data || 0);
            _this.isCompute = false;
          }, 500);
        }
      })
    },
    submit (refName) {
      let _this = this;
      this.$refs[refName].validate(valid => {
        if (valid) {
          _this.isLoading = true;
          _this[refName].payeeCountryId = _this[refName].country_region_city[0];
          _this[refName].payeeRegionId = _this[refName].country_region_city[1];
          _this[refName].payeeCityId = _this[refName].country_region_city[2];
          _this[refName].businessCode = _this.$route.params.code;

          transactionInfo.cashRemittance({
            param: _this[refName],
            success: res => {
              if (res.code == 200) {
                _this.$message.success(_this.$t('operateSuccessfully'));

                _this.$set(_this, "printParam", res.data)
                _this.$set(_this, "printVisible", true)
              }
              else {
                _this.$message.warning(_this.$t(res.msg));
              }
              _this.isLoading = false;
            }
          })
        }
        else return false;
      })
    }

  },
  mounted () {
  }
}
</script>

<style lang="less" scoped>
.cash-not-account.business-handling {
  height: 100%;
  width: 100%;
  .el-card__body {
    position: relative;
    .form-container {
      position: absolute;
      top: 50px;
      bottom: 80px;
      left: 20px;
      right: 0;
      padding-right: 20px;
      overflow: auto;
    }
  }
  .el-form {
    height: auto;
  }
}
</style>